<template>
  <div role="dialog" class="modal sketch-modal d-block" aria-modal="true" @keydown.esc="closeModal">
    <div class="modal-dialog modal-lg">
      <div tabindex="-1" class="modal-content">
        <header class="modal-header">
          <h5 class="modal-title">{{ $t("SelectAppointment") }}</h5>
          <button type="button" class="close" aria-label="Close" @click="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </header>
        <div class="modal-body">
          <b-container id="treatmentSketchAppointment">
            <b-table-simple v-if="appointmentList && appointmentList.length > 0" responsive striped>
              <b-thead>
                <b-th>{{ $t('Date') }}</b-th>
                <b-th>{{ $t('Staff') }}</b-th>
                <b-th>{{ $t('Services') }}</b-th>
                <b-th>{{ $t('Selection') }}</b-th>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(app,index) in appointmentList"
                      :key="index">
                  <b-td>{{ app.startDate }}</b-td>
                  <b-td>{{ app.staff }}</b-td>
                  <b-td>{{ app.services }}</b-td>
                  <b-td>
                    <div class="d-flex justify-content-center">
                      <input type="radio"
                             v-model="selectedAppId"
                             :value="app.id">
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-row class="mb-3">
              <b-col md="12">
                <label class="form-control-label">{{$t('SketchNotes')}}</label>
                <textarea class="form-control"
                          v-model="notes"
                          rows="3" />
              </b-col>
            </b-row>
          </b-container>
          <b-row>
            <b-col lg="12">
              <modal-footer-button @submit="submitSelectedAppointment()" :is-vue-modal="true"></modal-footer-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import ModalFooterButton from '../../components/shared/ModalFooterButton';

    import appointmentService from '../../services/appointment';

    export default {
          props: {
              customerId: String,
              submitSketch: Function,
              setSelectedAppointmentId: Function,
              setSketchNotes: Function
          },
          components: {
              ModalFooterButton
          },
          data() {
              return {
                  appointmentList: [],
                  selectedAppId: '',
                  notes: '',
              };
          },
          methods: {
              submitSelectedAppointment() {
                  this.closeModal();
                  this.submitSketch();
              },
              getCustomerAppointments() {
                  appointmentService.getAppointmentsForTreatmentSketch(this.customerId)
                      .then(response => {
                          this.appointmentList = response;
                      });
              },
              closeModal() {
                  this.$modal.hide('vue-modal');
              }
          },
          watch: {
              selectedAppId: function (val) {
                  if (val && val.length > 0)
                    this.setSelectedAppointmentId(val);
              },
              notes: function (val) {
                  this.setSketchNotes(val);
              }
          },
          mounted: function () {
              this.getCustomerAppointments();
          }
      }
</script>
<style scoped>
  .sketch-modal {
      overflow-y: auto;
  }
</style>
