<template>
  <b-row v-if="canvas" no-gutters>
    <b-col lg="10" id="canvasPageBody" class="bg-white" style="height: 100vh; overflow: hidden;">
      <Loader v-if="isLoading" :is-visible="isLoading" class="vh-100"></Loader>
      <canvas id="canvas" style="cursor:crosshair !important;"></canvas>
    </b-col>
    <b-col lg="2" class="text-center bg-white shadow-lg" style="height: 100vh; overflow-x: hidden; overflow-y: scroll;">
      <template>
        <b-row class="px-3">
          <b-col>
            <b-button v-if="addedState.length > 0" @click="undo()" class="btn btn-sm btn-danger btn-block mt-3"><i class="fa fa-undo"></i> {{$t('Undo')}}</b-button>
          </b-col>
        </b-row>

        <hr />
        <div>
          <span>{{$t('Color')}}</span>
          <br />
          <color-picker v-bind="color" @input="updateColor" style="width:145px !important; height:145px !important; margin-left: auto; margin-right: auto;"></color-picker>
        </div>

        <hr />
        <div>
          <span>{{$t('Brush')}}</span>
          <br />
          <vue-slider style="width:80%; margin:auto;" v-model="brushWidth" :min="1" :max="50" :interval="1" />
        </div>

        <hr />
        <div>
          <input type="radio" class="radio-button" :value="'draw'" v-model="canvasMode"> {{$t('SketchMode')}} <br />
          <input type="radio" class="radio-button" :value="'arrow'" v-model="canvasMode"> {{$t('ArrowMode')}} <br />
          <input type="radio" class="radio-button" :value="'circle'" v-model="canvasMode"> {{$t('CircleMode')}}  <br />
          <input type="radio" class="radio-button" :value="'spray'" v-model="canvasMode"> {{$t('SprayMode')}} <br />
          <input type="radio" class="radio-button" :value="'selection'" v-model="canvasMode">{{$t('SelectionMode')}} <br />
        </div>

        <hr />
        <div>
          <button @click="addTextToCanvas()" class="btn btn-sm btn-outline-success"><i class="fa fa-font mr-1"></i>{{$t('AddText')}}</button>
        </div>

        <b-row class="px-3 pb-3">
          <b-col>
            <b-button v-if="isObjectSelected" @click="deleteSelectedObject()" class="btn btn-sm btn-danger btn-block"><i class="fa fa-trash"></i> {{ $t("DeleteSelected") }}</b-button>
          </b-col>
        </b-row>

        <input type="checkbox" v-model="serviceSelectionDisabled" /> {{$t('DontShowSelectedTreatment')}}

        <hr />
        <b-row class="px-3">
          <b-col lg="6">
            <b-button v-if="!isSubmitting" type="button" class="btn btn-block btn-secondary" @click="goBack()">{{ $t('Cancel') }}</b-button>
          </b-col>
          <b-col lg="6">
            <b-overlay :show="isSubmitting"
                       spinner-small
                       spinner-variant="primary">
              <b-button :disabled="isSubmitting"
                        variant="primary"
                        class="btn-block"
                        @click="showSaveModal()">
                {{ $t('Save') }}
              </b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </template>
    </b-col>
  </b-row>
</template>

<script>
  import Loader from '../../../components/shared/Loader';
  import TreatmentSketch from '../../../components/treatmentSketch/TreatmentSketchService';
  import TreatmentSketchAppointment from '../../../components/treatmentSketch/TreatmentSketchAppointment';


  import '../../../plugins/vue-slider';
  import '../../../plugins/vue-modal';
  import { fabric } from 'fabric-browseronly';
  import { getUniqueString } from '../../../utils/stringExtensions';
  import ColorPicker from '@radial-color-picker/vue-color-picker';

  import multimediaService from '../../../services/multimedia';
  import customerSketchService from '../../../services/customerSketch';

  export default {
    name: 'CustomerSketch',
    props: {
      multimediaId: String,
      sketchTemplateId: String,
      customerSketchId: String,
      customerId: String,
      isModal: Boolean
    },
    components: {
      Loader,
      ColorPicker
    },
    data() {
      return {
        undoAllowed: false,
        redoAllowed: false,
        brushWidth: 5,
        isLoading: true,
        sourceBase64: '',
        canvas: {},
        canvasMode: 'draw',
        color: {
          hue: 240,
          saturation: 100,
          luminosity: 50,
          alpha: 1
        },
        hslColor: 'hsl(240,100%,50%)',
        ObjectList: [],
        isObjectSelected: false,
        selectedObject: {},
        deletedObject: {},
        serviceSelectionDisabled: false,
        addedLeft: 0,
        addedTop: 0,
        addedState: [],
        removedState: [],
        currentState: 0,
        textTopMarginer: 0,
        selectedAppointmentId: '',
        selectedStaffId: '',
        sketchNotes: '',
        componentName: '',
        componentHeader: '',
        isSubmitting: false
      };
    },
    methods: {
      undo() {
        const self = this;
        const lastItemIndex = (this.addedState.length - 1);
        if (lastItemIndex > -1) {
          let stateItem = this.addedState[lastItemIndex];
          let canvasItem = this.canvas.getObjects().filter(el => el == stateItem)[0];

          this.canvas.remove(canvasItem);
          this.addedState.splice(lastItemIndex, 1);
          this.canvas.renderAll();

          if (canvasItem && canvasItem.get('type') === 'text') {
            for (var i = 0; i < self.ObjectList.length; i++) {
              if (self.ObjectList[i].name == canvasItem.name) {
                self.ObjectList.splice(i, 1);
                self.undo();
                break;
              }
            }
          }
        }
      },
      redo() {
        const lastItemIndex = (this.removedState.length - 1);
        if (lastItemIndex > -1) {
          let stateItem = this.removedState[lastItemIndex];

          this.canvas.add(stateItem);
          this.removedState.splice(lastItemIndex, 1);
          this.canvas.renderAll();
        }
      },
      updateColor(hue) {
        this.color.hue = hue;
        this.hslColor = "hsl(" + this.color.hue + "," + this.color.saturation + "%," + this.color.luminosity + "%)";
        this.canvas.freeDrawingBrush.color = this.hslColor;
      },
      addTextToCanvas() {
        const $this = this;
        var text = prompt("Text", "");

        if (text) {

          var textObj = new fabric.Text(text, {
            fontFamily: 'Helvetica',
            left: 100,
            top: 100,
            fontSize: 25,
            backgroundColor: "#fff",
            opacity: 0.8,
            fill: $this.hslColor,
            name: getUniqueString()
          });
          this.canvas.add(textObj);
          this.ObjectList.push({ name: textObj.name, serviceId: null, serviceName: null, productId: null, productName: null, text: text, price: 0, currencyId: null, quantity: 0, color: $this.hslColor, userInputDiscount: null, rowTotal: 0 });

          this.canvasMode = "selection";
        }
      },
      setCanvasMode() {
        let canvas = this.canvas;
        this.canvas.freeDrawingBrush.width = this.brushWidth;

        if (this.canvasMode == "arrow") {
          canvas.freeDrawingBrush.color = "transparent";
          canvas.isDrawingMode = true;
          canvas.selection = false;
          canvas.selectionColor = "transparent";
          canvas.selectionBorderColor = "transparent";
          try {
            canvas.discardActiveObject().renderAll();
          } catch (e) {
            console.error(e);
          }
          canvas.renderAll();
        }
        else if (this.canvasMode == "selection") {
          canvas.isDrawingMode = false;
          canvas.selection = true;

        }
        else if (this.canvasMode == "spray") {
          this.canvas.isDrawingMode = true;
          this.canvas.selection = false;

          this.canvas.freeDrawingBrush = new fabric['SprayBrush'](this.canvas);
          this.canvas.freeDrawingBrush.color = this.hslColor;
        }
        else if (this.canvasMode == "circle") {
          this.canvas.isDrawingMode = true;
          this.canvas.selection = false;

          this.canvas.freeDrawingBrush = new fabric['CircleBrush'](this.canvas);
          this.canvas.freeDrawingBrush.color = this.hslColor;
        }
        else {
          this.canvas.isDrawingMode = true;
          this.canvas.selection = false;
          this.canvas.freeDrawingBrush = new fabric['PencilBrush'](this.canvas);
          this.canvas.freeDrawingBrush.color = this.hslColor;
          this.canvas.freeDrawingBrush.width = this.brushWidth;

        }
        canvas.renderAll();
      },
      newCanvas() {
        let $this = this;

        this.canvas = new fabric.Canvas('canvas', {
          targetFindTolerance: 15,
          selection: true
        });

        var parentElement = document.getElementById("canvasPageBody");
        this.canvas.setWidth(parentElement.offsetWidth);
        this.canvas.setHeight(window.innerHeight);
        this.canvas.renderAll();

        this.canvas.on('object:added', function (evt) {
          var addedObject = evt.target;
          var ObjectType = evt.target.get('type');

          if (!$this.serviceSelectionDisabled && ObjectType == "path") {
            $this.showServiceBox();
            $this.addedLeft = addedObject.left;
            $this.addedTop = addedObject.top;
            $this.textTopMarginer = 20;
          }
          else {
            $this.addedLeft = addedObject.left;
            $this.addedTop = addedObject.top;
            $this.textTopMarginer = 20;
          }

          $this.addedState.push(addedObject);
        });

        this.canvas.on('object:removed', function (evt) {
          $this.removedState.push(evt.target);
        });

        this.canvas.on('object:selected', function (evt) {
          $this.isObjectSelected = true;
          $this.selectedObject = evt.target;
        });

        this.canvas.on('selection:created', function () {
          $this.isObjectSelected = true;
        });

        this.canvas.on('selection:cleared', function () {
          $this.isObjectSelected = false;
        });

        const LineWithArrow = fabric.util.createClass(fabric.Line, {
          type: 'line_with_arrow',
          hasBorders: false,
          hasControls: false,
          _getCacheCanvasDimensions() {
            var dim = this.callSuper('_getCacheCanvasDimensions');
            dim.width += 15; // found by trial and error
            dim.height += 15; // found by trial and error
            return dim;
          },

          _render(ctx) {
            this.callSuper('_render', ctx);
            ctx.save();
            const xDiff = this.x2 - this.x1;
            const yDiff = this.y2 - this.y1;
            const angle = Math.atan2(yDiff, xDiff);
            ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
            ctx.rotate(angle);
            ctx.beginPath();
            // Move 5px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
            ctx.moveTo(5, 0);
            ctx.lineTo(-5, 5);
            ctx.lineTo(-5, -5);
            ctx.closePath();
            ctx.fillStyle = $this.hslColor;
            ctx.fill();
            ctx.strokeWidth = $this.brushWidth;
            ctx.stroke();
            ctx.restore();
          },
        });

        const drawLineWithArrow = (points) => (
          new LineWithArrow(points, {
            strokeWidth: $this.brushWidth,
            stroke: $this.hslColor
          })
        )

        const selectLine = (points) => {
          return drawLineWithArrow(points);
        }

        let line;
        let isDown;

        this.canvas.on('mouse:down', (options) => {
          if ($this.ARROWMODE === true) {
            isDown = true;
            const pointer = $this.canvas.getPointer(options.e);
            const points = [pointer.x, pointer.y, pointer.x, pointer.y];
            line = selectLine(points);
            $this.canvas
              .add(line)
              .setActiveObject(line)
              .renderAll();
          }
        });

        this.canvas.on('mouse:move', (options) => {
          if ($this.ARROWMODE === true) {
            if (!isDown) return;
            const pointer = $this.canvas.getPointer(options.e);
            line.set({ x2: pointer.x, y2: pointer.y });
            $this.canvas.renderAll();
          }
        });

        this.canvas.on('mouse:up', () => {
          if ($this.ARROWMODE === true) {
            isDown = false;
            line.setCoords();
            $this.canvas.setActiveObject(line).renderAll();
          }
        });

        window.addEventListener('resize', function () { $this.resizeCanvas() })

        $this.resizeCanvas();
        $this.setCanvasMode("draw");
        $this.canvas.freeDrawingBrush.color = $this.hslColor;
        $this.canvas.freeDrawingBrush.width = $this.brushWidth;

        if (this.multimediaId && this.multimediaId.length > 0)
          $this.getBase64ForMultimediaId();
        else if (this.sketchTemplateId && this.sketchTemplateId.length > 0)
          $this.getBase64ForSketchTemplateId();
        else
          $this.getBase64ForSketchId();
      },
      getBase64ForMultimediaId() {
        this.isLoading = true;
        multimediaService.getBase64ForMultimediaId(this.multimediaId)
          .then(response => {
            this.sourceBase64 = response;
            this.setBg()
            this.isLoading = false;
          });
      },
      getBase64ForSketchTemplateId() {
        this.isLoading = true;
        customerSketchService.getBase64ForSketchTemplateId(this.sketchTemplateId)
          .then(response => {
            this.sourceBase64 = response;
            this.setBg()
            this.isLoading = false;
          });
      },
      getBase64ForSketchId() {
        this.isLoading = true;
        customerSketchService.getBase64ForCustomerSketchId(this.customerSketchId)
          .then(response => {
            this.sourceBase64 = response;
            this.setBg()
            this.isLoading = false;
          });
      },
      setBg() {
        var myDataURL = "data:image/png;base64, " + this.sourceBase64;
        let canvas = this.canvas;
        var center = canvas.getCenter();

        var img = new Image();
        img.onload = function () {
          var f_img = new fabric.Image(img);
          var hRatio = canvas.width / f_img.width;
          var vRatio = canvas.height / f_img.height;
          canvas.backgroundColor = "white";

          if (f_img.height > canvas.height) {

            canvas.setBackgroundImage(f_img, canvas.renderAll.bind(canvas), {
              scaleX: hRatio * ((canvas.height / canvas.width) / (f_img.height / f_img.width)),
              scaleY: vRatio,
              top: center.top,
              left: center.left,
              originX: 'center',
              originY: 'center'

            });
          }
          else if (f_img.width > canvas.width) {

            canvas.setBackgroundImage(f_img, canvas.renderAll.bind(canvas), {
              scaleX: hRatio * (f_img.height / f_img.width),
              scaleY: vRatio,
              top: center.top,
              left: center.left,
              originX: 'center',
              originY: 'center'

            });
          }
          else {
            canvas.setBackgroundImage(f_img, canvas.renderAll.bind(canvas), {
              scaleX: 1,
              scaleY: 1,
              top: center.top,
              left: center.left,
              originX: 'center',
              originY: 'center'

            });
          }
        };
        img.src = myDataURL;
      },
      getItemFromArrayByName(name) {
        let $this = this;
        var object = null,
          objects = $this.ObjectList;

        for (var i = 0, len = objects.length; i < len; i++) {
          if (objects[i].name && objects[i].name === name) {
            object = objects[i];
            break;
          }
        }

        return object;
      },
      deleteSelectedObject() {
        let $this = this;
        const x = $this.canvas.getActiveObjects();
        for (var i = 0; i < x.length; i++) {

          if (x[i].name) {
            var _obj = $this.getItemFromArrayByName(x[i].name);
            if (_obj && _obj != null) {
              var index = $this.ObjectList.indexOf(_obj);
              if (index > -1) {
                $this.ObjectList.splice(index, 1);
              }
            }
          }

          $this.canvas.remove(x[i]);
        }

        try {
          $this.canvas.discardActiveObject().renderAll();
        } catch (e) {
          console.dir(e);
        }
      },
      ServiceSelected(type, id, name, price, currencyId, quantity, userInputDiscount, useCreditPrice, rowTotal) {
        let $this = this
        let textObj = new fabric.Text(name, {
          fontFamily: 'Helvetica',
          left: $this.addedLeft,
          top: ($this.addedTop - 20) + $this.textTopMarginer,
          fill: $this.hslColor,
          fontSize: 16,
          backgroundColor: "#fff",
          opacity: 0.8,
          name: getUniqueString()
        });
        this.canvas.add(textObj);

        let serviceid = type == 'service' ? id : null;
        let servicename = type == 'service' ? name : null;
        let productid = type == 'product' ? id : null;
        let productname = type == 'product' ? name : null;

        this.ObjectList.push({ name: textObj.name, serviceId: serviceid, serviceName: servicename, productId: productid, productName: productname, text: name, price: price, currencyId: currencyId, quantity: quantity, color: $this.hslColor, userInputDiscount: userInputDiscount, useCreditPrice: useCreditPrice, rowTotal: rowTotal });

        this.canvasMode = "selection";
      },
      showServiceBox() {
        let $this = this;
        $this.$modal.show(
          TreatmentSketch,
          {
            customerId: this.customerId,
            setTextTopMarginer: this.setTextTopMarginer,
            setSelectedStaffId: this.setSelectedStaffId,
            serviceSelected: this.ServiceSelected
          },
          {
            width: 1000
          },
          {
            'before-close': () => { }
          }
        )

        return false;
      },
      resizeCanvas() {
        const outerCanvasContainer = document.getElementById('canvasPageBody');
        let canvas = this.canvas;

        const ratio = canvas.getWidth() / canvas.getHeight();
        const containerWidth = outerCanvasContainer ? outerCanvasContainer.clientWidth : 0;

        const scale = containerWidth / canvas.getWidth();
        const zoom = canvas.getZoom() * scale;

        canvas.setDimensions({ width: containerWidth, height: containerWidth / ratio });
        canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
      },
      showSaveModal() {
        let $this = this;
        $this.$modal.show(
          TreatmentSketchAppointment,
          {
            customerId: this.customerId,
            submitSketch: this.submitSketch,
            setSelectedAppointmentId: this.setSelectedAppointmentId,
            setSketchNotes: this.setSketchNotes,
          },
          {
            width: 1000
          },
          {
            'before-close': () => { }
          }
        )

        return false;
      },
      getBase64() {
        let pngUrl = this.canvas.toDataURL();
        return pngUrl;
      },
      goBack() {
        this.$router.go(-1)
      },
      closeModal() {
        this.$modal.hide('vue-modal');
      },
      setTextTopMarginer(newValue) {
        this.textTopMarginer = this.textTopMarginer + newValue;
      },
      setSelectedStaffId(newValue) {
        this.selectedStaffId = newValue;
      },
      setSelectedAppointmentId(newValue) {
        this.selectedAppointmentId = newValue;
      },
      setSketchNotes(newValue) {
        this.sketchNotes = newValue;
      },
      submitSketch() {
        let $this = this;
        this.isSubmitting = true;
        customerSketchService.postCustomerSketch({
            base64: $this.getBase64(),
            customerMultimediaId: $this.multimediaId,
            customerId: $this.customerId,
            customerSketchId: $this.customerSketchId,
            appointmentId: $this.selectedAppointmentId,
            staffId: $this.selectedStaffId,
            customerSketchTemplateId: $this.sketchTemplateId,
            notes: $this.sketchNotes,
            fabricObject: $this.ObjectList
          })
          .then(response => {
            if (response) {
              this.$toastr.success(this.$t("Success"));
              this.closeModal();
              this.$router.push({ name: 'customerSummary.multimedias', params: { tabName: 'sketch' } })
            }
            else {
              this.$toastr.error(this.$t("Error"));
            }
          }).finally(() => { this.isSubmitting = false; });
      },
    },
    watch: {
      canvasMode: function () {
        this.setCanvasMode()
      },
      brushWidth: function (val) {
        this.canvas.freeDrawingBrush.width = val;
        return val;
      },
      serviceSelectionDisabled: function (val) {
        if (!val)
          this.showServiceBox();
      }
    },
    computed: {
      ARROWMODE() {
        return this.canvasMode == "arrow"
      }
    },
    mounted() {
      this.brushWidth = 5;
      this.newCanvas();
    },
  }
</script>
<style>
  @import '~@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css';

  #canvas {
    border: 1px solid #ccc;
  }
</style>
