<template>
  <div role="dialog" class="modal sketch-modal d-block" aria-modal="true" @keydown.esc="closeModal">
      <div class="modal-dialog modal-lg">
          <div tabindex="-1" class="modal-content">
              <header class="modal-header">
                  <h5 class="modal-title">{{ $t("AppointmentComponent.SelectService") }}</h5>
                  <button type="button" class="close" aria-label="Close" @click="closeModal()">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </header>
              <div class="modal-body">
                  <b-row>
                      <label class="col-lg-2 col-form-label">
                          <span>{{ $t("Staff") }}</span>
                      </label>
                      <b-col md="6">
                          <v-select transition="" v-model="staffId"
                                    :reduce="s => s.id" label="name"
                                    :options="staffList">
                          <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                      </b-col>
                      <b-col md="4" style="text-align:right;">
                          <button type="button" class="btn btn-primary btn-sm" @click="addEntityRow">
                              <i class="fa fa-plus" /> {{ $t("AddNewLine") }}
                          </button>
                          <br /><br />
                      </b-col>
                  </b-row>
                  <b-row>
                      <treatment-sketch-service-row v-for="(tss, tssIndex) in serviceRows" :key="tssIndex" :initial-tss="tss" :tss-index="tssIndex" :staff-id="staffId" />
                  </b-row>
                  <b-row>
                      <b-col md="12">
                          <modal-footer-button @submit="submitServiceRows()" :is-vue-modal="true"></modal-footer-button>
                      </b-col>
                  </b-row>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
    import ModalFooterButton from '../../components/shared/ModalFooterButton';
    import TreatmentSketchServiceRow from '../../components/treatmentSketch/TreatmentSketchServiceRow';

    import '../../plugins/sweetalert';
    import staffService from '../../services/staff';
    import customerSketchService from '../../services/customerSketch';

    export default {
        props: {
            customerId: String,
            setTextTopMarginer: Function,
            setSelectedStaffId: Function,
            serviceSelected: Function
        },
        components: {
            ModalFooterButton,
            TreatmentSketchServiceRow
        },
        data() {
            return {
                serviceRows: [{
                    entityId: '',
                    quantity: 1,
                    userInputDiscount: '',
                    useCreditPrice: false,
                    currencyId: this.$store.getters['Auth/tenantInfo'].mainCurrencyId,
                    rowTotal: 0,
                    entity: { entityType: '', id: '', name: '', entityCategoryName: '', basePrice: 0, basePriceWithCard: 0, currencyId: this.$store.getters['Auth/tenantInfo'].mainCurrencyId }
                }],
                currencyList: [],
                staffId: '',
                totalEntityCount: 1,
                disabledButton: false,
                staffList: [],
                parityList: [],
                globalSaleTotal: 0,
                mainCurrencySymbol: '',
                customerDiscount: '',
                hasDiscount: false
            };
        },
        methods: {
            submitServiceRows() {
                let $this = this;

                if ($this.hasAnySalesRow) {
                    $this.disabledButton = true;
                    $this.setSelectedStaffId($this.staffId);
                    $this.serviceRows.forEach(z => {
                        $this.serviceSelected(z.entity.entityType, z.entity.id, z.entity.name, (z.useCreditPrice ? z.entity.basePriceWithCard : z.entity.basePrice), z.currencyId, z.quantity, z.userInputDiscount, z.useCreditPrice, z.rowTotal.toString());
                        $this.setTextTopMarginer(20);
                    });

                    $this.serviceRows = [{
                        entityId: '',
                        quantity: 1,
                        userInputDiscount: '',
                        useCreditPrice: false,
                        currencyId: this.$store.getters['Auth/tenantInfo'].mainCurrencyId,
                        rowTotal: 0,
                      entity: { entityType: '', id: '', name: '', entityCategoryName: '', basePrice: 0, basePriceWithCard: 0, currencyId: this.$store.getters['Auth/tenantInfo'].mainCurrencyId }
                    }];
                    $this.staffId = '';
                    $this.closeModal();
                    $this.disabledButton = false;
                }
            },
            closeModal() {
                this.$modal.hide('vue-modal');
            },
            calculateTotal() {
                let saleTotal = 0;
                for (let i = 0; i < this.serviceRows.length; i++) {
                    let saleRow = this.serviceRows[i];
                    saleTotal += this.currencyExchange(saleRow.rowTotal, saleRow.currencyId, this.$store.getters['Auth/tenantInfo'].mainCurrencyId);
                }

                this.globalSaleTotal = saleTotal.toFixed(2);

            },
            getStaffList() {
                staffService.getStaffList((this.staffId && this.staffId.length > 0) ? { params: { id: this.staffId, forSaleRep: 0, onlyShowInCalendar: 0 } } : { params: { id: null, forSaleRep: 0, onlyShowInCalendar: 0 } }).then(response => { this.staffList = response });
            },
            getCurrencyList() {
                //this.$axios.get("/currency/listJson").then(response => {
                //    this.mainCurrencySymbol = this.$store.getters.tenantInfo.mainCurrencySymbol;
                //    this.currencyList = response.data;
                //    this.getParities();
                //});
            },
            getAppointmentStaff() {
                customerSketchService.getAppointmentStaffForCustomerSketch(this.customerId).then(response => {
                    if (response)
                        this.staffId = response;
                    else
                        this.staffId = this.$store.getters['Auth/currentStaff'].id;

                    this.getStaffList();
                });
            },
            getParities() {
                //this.$axios.get("/currency/listParities").then(response => {
                //    this.parityList = response.data;
                //});
          },
            addEntityRow() {
                this.totalEntityCount++;
                this.serviceRows.push({
                    entityId: '',
                    quantity: 1,
                    userInputDiscount: 0,
                    useCreditPrice: false,
                    currencyId: this.$store.getters['Auth/tenantInfo'].mainCurrencyId,
                    rowTotal: 0,
                    entity: { entityType: '', id: '', name: '', entityCategoryName: '', basePrice: 0, basePriceWithCard: 0, currencyId: this.$store.getters['Auth/tenantInfo'].mainCurrencyId }
                })
            },
            removeRow(item) {
                this.totalEntityCount--;
                this.serviceRows.splice(this.serviceRows.indexOf(item), 1);
            },
            currencyExchange(rowTotal, toCurrency, fromCurrency) {
                let foundParity = this.parityList.find(el => el.fromCurrencyId == fromCurrency && el.toCurrencyId == toCurrency);
                if (!foundParity)
                    return (rowTotal * 1);
                else
                    return (rowTotal * foundParity.parity);

            },
            getCustomerDiscountGroup() {
                //if (!this.hasDiscount) {
                //    this.$axios.get("/customer/getCustomerDiscountGroup?customerId=" + this.customerId)
                //        .then(response => {
                //            if (response.data) {
                //                this.hasDiscount = true;
                //                this.dicountGroupWarning(response.data.name, response.data.percentage);
                //            }
                //        });
                //}
            },
            dicountGroupWarning(discountGroupName, percentage) {
                let $this = this;
                let _html = `<hr/><div class='col-md-12 row' style='margin-top: 1%;'><div class='col-md-12'><h5>${discountGroupName}: %${percentage}</h5><br/>${$this.$t("DiscountGroupWarning")}</div></div><hr/>`;
                $this.$swal.fire({
                    title: "<i class='fa fa-exclamation-triangle' style='font-size:35px;'></i>",
                    html: _html,
                    buttonsStyling: false,
                    confirmButtonText: $this.$t("DiscountButton"),
                    confirmButtonClass: "btn btn-brand btn-sm",
                    showCancelButton: true,
                    cancelButtonText: $this.$t("Cancel"),
                    cancelButtonClass: "btn btn-secondary btn-sm",
                    reverseButtons: true

                }).then(function (result) {
                    if (result.value) {
                        $this.customerDiscount = `%${percentage}`;
                    } else if (result.dismiss === 'cancel') { return; }
                });
            }
        },
        watch: {
            serviceRows: {
                handler: function () {
                    this.calculateTotal();

                    if (this.serviceRows.length == 1)
                        this.totalEntityCount = 1;
                },
                deep: true
            }
        },
        computed: {
            multimediaId: function () { return this.$router.currentRoute.params.multimediaId },
            rowCount: function () { return this.serviceRows.length; },
            hasAnySalesRow: function () {
                return this.serviceRows && this.serviceRows.filter(el => el.entityId && el.entityId.length > 0).length > 0;
            }
        },
        mounted: function () {
            this.getAppointmentStaff();
            this.getCurrencyList();
        }
    }
</script>
<style scoped>
  .sketch-modal {
      overflow-y: auto;
  }
</style>
